export default {
  'pages.layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'page.login.accountLogin.tab': '账户密码登录',
  'page.login.accountLogin.errorMessage': '错误的用户名和密码（admin/ant.design)',
  'page.login.username.placeholder': '用户名: admin or user',
  'page.login.username.required': '用户名是必填项！',
  'page.login.password.placeholder': '密码: ant.design',
  'page.login.password.required': '密码是必填项！',
  'page.login.phoneLogin.tab': '手机号登录',
  'page.login.phoneLogin.errorMessage': '验证码错误',
  'page.login.phoneNumber.placeholder': '请输入手机号！',
  'page.login.phoneNumber.required': '手机号是必填项！',
  'page.login.phoneNumber.invalid': '不合法的手机号！',
  'page.login.captcha.placeholder': '请输入验证码！',
  'page.login.captcha.required': '验证码是必填项！',
  'page.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'page.login.rememberMe': '自动登录',
  'page.login.forgotPassword': '忘记密码 ?',
  'page.login.submit': '登录',
  'page.login.iPhone': '切换移动客户端',
  'page.login.loginWith': '其他登录方式 :',
  'page.login.registerAccount': '注册账户',
  'pages.welcome.advancedComponent': '高级表格',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.advancedLayout': '高级布局',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.searchTable.createForm.newRule': '新建规则',
  'pages.searchTable.updateForm.ruleConfig': '规则配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '规则名称',
  'pages.searchTable.updateForm.ruleName.nameRules': '请输入规则名称！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '规则描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '请输入至少五个字符',
  'pages.searchTable.updateForm.ruleDesc.descRules': '请输入至少五个字符的规则描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置规则属性',
  'pages.searchTable.updateForm.object': '监控对象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '规则模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '规则类型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '设定调度周期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '规则名称为必填项',
  'pages.searchTable.titleCallNo': '服务调用次数',
  'pages.searchTable.titleStatus': '状态',
  'pages.searchTable.nameStatus.default': '关闭',
  'pages.searchTable.nameStatus.running': '运行中',
  'pages.searchTable.nameStatus.online': '已上线',
  'pages.searchTable.nameStatus.abnormal': '异常',
  'pages.searchTable.titleUpdatedAt': '上次调度时间',
  'pages.searchTable.exception': '请输入异常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '订阅警报',
  'pages.searchTable.title': '查询表格',
  'pages.searchTable.new': '新建',
  'pages.searchTable.chosen': '已选择',
  'pages.searchTable.item': '项',
  'pages.searchTable.totalServiceCalls': '服务调用次数总计',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': '批量删除',
  'pages.searchTable.batchApproval': '批量审批',


  'pages.workplace.CompanyAnnouncement': '公司公告',
  'pages.workplace.ImportantInformation': '重要信息',
  'pages.workplace.GoodMorning': '欢迎来到成诚管理公司自助系统',
  'pages.workplace.happy': '祝你开心每一天',
  'pages.workplace.progress': '进度查询',
  'pages.workplace.announcement': '未读公告',
  'pages.workplace.customer': '未读客服信息',
  'pages.workplace.Personal': '个人资料未填写',
  'pages.workplace.pay': '工资状态',
  "pages.workplace.receipted_man_hour": "收到确认工时",
  "pages.workplace.payment_request_submitted": "已向客户请款",
  "pages.workplace.remittance_received": "收到客户已付款通知",
  "pages.workplace.receipted_payment": "收到客户付款",
  "pages.workplace.paid": "已付款",
  'router.title.workplace': '欢迎页', // '欢迎页'
  'router.title.personal_information': '个人资料', // '个人信息'
  'router.title.notice': '公告详情', // '公告详情'
  'router.title.cerp-beneficiary': '保险受益人', // '保险受益人'
  'router.title.adviser-manage': '数据管理', // '数据管理'
  'router.title.cerp-bankaccount': '顾问银行账户', // '顾问银行账户'
  'router.title.cerp-personreferencenumber': '个人材料', // '个人材料'
  'router.title.cerp-nextofkin': '紧急联系人', // '紧急联系人'
  'router.title.cerp-contract': '劳务合同', // '合同'
  'router.title.cerp-insurance': '保险信息', // '保险'
  'router.title.cerp-payslip': '工资明细查询', // '工资单'
  'router.title.payment_progress': '工资发放进度', // '付款状态'
  'router.title.Induction_process': '入职流程', // '入职流程'
  'router.title.curriculum_vitae': '个人简历', // '个人简历'
  'router.title.kefu': '联系客服', // '个人简历'
  'router.title.kefu_question': '问题回复', // '问题回复'
  'page.cerp.beneficiary.column.title.action': '操作',
  'page.cerp.beneficiary.column.title.create_time': '创建时间',
  'page.cerp.beneficiary.column.title.date_of_birth': '出生日期',
  'page.cerp.beneficiary.column.title.gender': '性别',
  'page.cerp.beneficiary.column.title.id': 'ID',
  'page.cerp.beneficiary.column.title.id_expiry_date': '证件有效期',
  'page.cerp.beneficiary.column.title.relation': '与被保险人关系',
  'page.cerp.beneficiary.column.title.ratio': '受益比例',
  'page.cerp.beneficiary.column.title.id_number': '证件号',
  'page.cerp.beneficiary.column.title.id_type__name': '证件类别名',
  'page.cerp.beneficiary.column.title.index': '序号',
  'page.cerp.beneficiary.column.title.insurance__policy_no': '保险单号',
  'page.cerp.beneficiary.column.title.is_active': '是否可用',
  'page.cerp.beneficiary.column.title.name': '姓名',
  'page.cerp.beneficiary.column.title.adviser__full_name': '顾问名',
  'page.cerp.beneficiary.column.title.adviser__employee_number': '顾问工号',
  'page.cerp.beneficiary.column.title.update_time': '更新时间',
  'page.cerp.beneficiary.form.label.adviser_id': '隶属顾问',
  'page.cerp.beneficiary.form.label.date_of_birth': '出生日期',
  'page.cerp.beneficiary.form.label.gender': '性别',
  'page.cerp.beneficiary.form.label.id_expiry_date': '证件有效期',
  'page.cerp.beneficiary.form.label.relation': '与被保险人关系',
  'page.cerp.beneficiary.form.label.ratio': '受益比例(%)',
  'page.cerp.beneficiary.form.label.id_number': '证件号',
  'page.cerp.beneficiary.form.label.id_type_id': '证件类别',
  'page.cerp.beneficiary.form.label.name': '姓名',
  'page.cerp.beneficiary.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.beneficiary.form.placeholder.date_of_birth': '请填写出生日期',
  'page.cerp.beneficiary.form.placeholder.id_expiry_date': '请填写证件有效期',
  'page.cerp.beneficiary.form.placeholder.relation': '请填写与被保险人关系',
  'page.cerp.beneficiary.form.placeholder.ratio': '请填写受益比例',
  'page.cerp.beneficiary.form.placeholder.id_number': '请填写证件号',
  'page.cerp.beneficiary.form.placeholder.id_type_id': '请选择证件类别',
  'page.cerp.beneficiary.form.placeholder.name': '请填写姓名',
  'page.cerp.bankaccount.column.title.account_holder': '账户持有人',
  'page.cerp.bankaccount.column.title.account_number': '账号',
  'page.cerp.bankaccount.column.title.action': '操作',
  'page.cerp.bankaccount.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.bankaccount.column.title.bank_address': '开户行地址',
  'page.cerp.bankaccount.column.title.bank_name': '银行名称',
  'page.cerp.bankaccount.column.title.bic_or_swift_code': 'bic_or_swift_code',
  'page.cerp.bankaccount.column.title.cnaps': 'cnaps',
  'page.cerp.bankaccount.column.title.create_time': '创建时间',
  'page.cerp.bankaccount.column.title.english_name': '银行英文名称',
  'page.cerp.bankaccount.column.title.iban': 'iban',
  'page.cerp.bankaccount.column.title.id': 'ID',
  'page.cerp.bankaccount.column.title.index': '序号',
  'page.cerp.bankaccount.column.title.insurance_company__company_name': '保险公司',
  'page.cerp.bankaccount.column.title.is_active': '是否可用',
  'page.cerp.bankaccount.column.title.is_current': '是否当前账号',
  'page.cerp.bankaccount.column.title.note': '备注',
  'page.cerp.bankaccount.column.title.holder_address': '开户时向银行提供的住址',
  'page.cerp.bankaccount.column.title.sort_code_or_branch_code': 'sort_code_or_branch_code',
  'page.cerp.bankaccount.column.title.update_time': '更新时间',
  'page.cerp.bankaccount.form.label.account_holder': '账户持有人',
  'page.cerp.bankaccount.form.label.account_number': '账号',
  'page.cerp.bankaccount.form.label.adviser__full_name': '顾问',
  'page.cerp.bankaccount.form.label.adviser_id': '隶属顾问',
  'page.cerp.bankaccount.form.label.bank_address': '开户行地址',
  'page.cerp.bankaccount.form.label.bank_name': '银行名称',
  'page.cerp.bankaccount.form.label.bic_or_swift_code': 'bic_or_swift_code',
  'page.cerp.bankaccount.form.label.cnaps': 'cnaps',
  'page.cerp.bankaccount.form.label.english_name': '银行英文名称',
  'page.cerp.bankaccount.form.label.iban': 'iban',
  'page.cerp.bankaccount.form.label.insurance_company_id': '保险公司',
  'page.cerp.bankaccount.form.label.is_active': '是否可用',
  'page.cerp.bankaccount.form.label.is_current': '是否当前账号',
  'page.cerp.bankaccount.form.label.kind': '账号种类',
  'page.cerp.bankaccount.form.label.sort_code_or_branch_code': 'sort_code_or_branch_code',
  'page.cerp.bankaccount.form.placeholder.account_holder': '请填写账户持有人',
  'page.cerp.bankaccount.form.placeholder.account_number': '请填写账号',
  'page.cerp.bankaccount.form.placeholder.adviser__full_name': '请选择隶属顾问',
  'page.cerp.bankaccount.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.bankaccount.form.placeholder.bank_address': '请填写开户行地址',
  'page.cerp.bankaccount.form.placeholder.bank_name': '请填写银行名称',
  'page.cerp.bankaccount.form.placeholder.bic_or_swift_code': '请填写bic_or_swift_code',
  'page.cerp.bankaccount.form.placeholder.cnaps': '请填写cnaps',
  'page.cerp.bankaccount.form.placeholder.english_name': '请填写银行英文名称',
  'page.cerp.bankaccount.form.placeholder.iban': '请填写iban',
  'page.cerp.bankaccount.form.placeholder.insurance_company_id': '请选择保险公司',
  'page.cerp.bankaccount.form.placeholder.is_current': '请填写是否当前账号',
  'page.cerp.bankaccount.form.placeholder.sort_code_or_branch_code':
    '请填写sort_code_or_branch_code',
  'page.cerp.personreferencenumber.column.title.action': '操作',
  'page.cerp.personreferencenumber.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.personreferencenumber.column.title.create_time': '创建时间',
  'page.cerp.personreferencenumber.column.title.description': '描述',
  'page.cerp.personreferencenumber.column.title.file_name': '附件',
  'page.cerp.personreferencenumber.column.title.end_date': '截止时间',
  'page.cerp.personreferencenumber.column.title.id': 'ID',
  'page.cerp.personreferencenumber.column.title.index': '序号',
  'page.cerp.personreferencenumber.column.title.is_active': '是否可用',
  'page.cerp.personreferencenumber.column.title.name': '名称',
  'page.cerp.personreferencenumber.column.title.reference_number_type__name': '材料类别',
  'page.cerp.personreferencenumber.column.title.appendix_json_list': '附件',
  'page.cerp.personreferencenumber.column.title.start_date': '开始时间',
  'page.cerp.personreferencenumber.column.title.update_time': '更新时间',
  'page.cerp.personreferencenumber.form.label.adviser__full_name': '隶属顾问',
  'page.cerp.personreferencenumber.form.label.adviser_id': '隶属顾问',
  'page.cerp.personreferencenumber.form.label.description': '描述',
  'page.cerp.personreferencenumber.form.label.end_date': '截止时间',
  'page.cerp.personreferencenumber.form.label.is_active': '是否可用',
  'page.cerp.personreferencenumber.form.label.name': '名称',
  'page.cerp.personreferencenumber.form.label.reference_number_type_id': '参考号类别',
  'page.cerp.personreferencenumber.form.label.search_text': '模糊搜索',
  'page.cerp.personreferencenumber.form.label.start_date': '开始时间',
  'page.cerp.personreferencenumber.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.personreferencenumber.form.placeholder.description': '请填写描述',
  'page.cerp.personreferencenumber.form.placeholder.end_date': '请填写截止时间',
  'page.cerp.personreferencenumber.form.placeholder.is_active': '请选择是否可用',
  'page.cerp.personreferencenumber.form.placeholder.name': '请填写名称',
  'page.cerp.personreferencenumber.form.placeholder.reference_number_type_id': '请选择参考号类别',
  'page.cerp.personreferencenumber.form.placeholder.search_text': '请填写搜索内容',
  'page.cerp.personreferencenumber.form.placeholder.start_date': '请填写开始时间',
  'page.baseinfo.emailtemplate.column.title.appendix_json_list': '附件',
  'page.cerp.nextofkin.column.title.action': '操作',
  'page.cerp.nextofkin.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.nextofkin.column.title.contact_number': '联系电话',
  'page.cerp.nextofkin.column.title.create_time': '创建时间',
  'page.cerp.nextofkin.column.title.email': '联系邮箱',
  'page.cerp.nextofkin.column.title.id': 'ID',
  'page.cerp.nextofkin.column.title.index': '序号',
  'page.cerp.nextofkin.column.title.is_active': '是否可用',
  'page.cerp.nextofkin.column.title.name': '姓名',
  'page.cerp.nextofkin.column.title.releationship': '关系',
  'page.cerp.nextofkin.column.title.update_time': '更新时间',
  'page.cerp.nextofkin.form.label.adviser__full_name': '隶属顾问',
  'page.cerp.nextofkin.form.label.adviser_id': '隶属顾问',
  'page.cerp.nextofkin.form.label.contact_number': '联系电话',
  'page.cerp.nextofkin.form.label.email': '联系邮箱',
  'page.cerp.nextofkin.form.label.is_active': '是否可用',
  'page.cerp.nextofkin.form.label.name': '姓名',
  'page.cerp.nextofkin.form.label.releationship': '关系',
  'page.cerp.nextofkin.form.label.search_text': '模糊搜索',
  'page.cerp.nextofkin.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.nextofkin.form.placeholder.contact_number': '请填写联系电话',
  'page.cerp.nextofkin.form.placeholder.email': '请填写联系邮箱',
  'page.cerp.nextofkin.form.placeholder.is_active': '请选择是否可用',
  'page.cerp.nextofkin.form.placeholder.name': '请填写姓名',
  'page.cerp.nextofkin.form.placeholder.releationship': '请填写关系',
  'page.cerp.nextofkin.form.placeholder.search_text': '请填写搜索内容',
  'page.cerp.insurance.column.title.action': '操作',
  'page.cerp.insurance.column.title.adviser.full_name': '隶属顾问',
  'page.cerp.insurance.column.title.appendix_json': '附件',
  'page.cerp.insurance.column.title.beneficiaries': '受益人',
  'page.cerp.insurance.column.title.create_time': '创建时间',
  'page.cerp.insurance.column.title.effective_date_time': '起保日期时间',
  'page.cerp.insurance.column.title.expiry_date_time': '停保日期时间',
  'page.cerp.insurance.column.title.id': 'ID',
  'page.cerp.insurance.column.title.index': '序号',
  'page.cerp.insurance.column.title.insurance_company.company_name': '保险公司',
  'page.cerp.insurance.column.title.insurance_fee': '保费',
  'page.cerp.insurance.column.title.is_active': '是否可用',
  'page.cerp.insurance.column.title.is_current': '当前保险',
  'page.cerp.insurance.column.title.month_insurance_fee': '每月保费',
  'page.cerp.insurance.column.title.policy_no': '保险单号',
  'page.cerp.insurance.column.title.update_time': '更新时间',
  'page.cerp.insurance.form.label.adviser__full_name': '顾问',
  'page.cerp.insurance.form.label.adviser_id': '隶属顾问',
  'page.cerp.insurance.form.label.effective_date_time': '起保日期时间',
  'page.cerp.insurance.form.label.expiry_date_time': '停保日期时间',
  'page.cerp.insurance.form.label.insurance_company_id': '保险公司',
  'page.cerp.insurance.form.label.insurance_fee': '保费',
  'page.cerp.insurance.form.label.is_current': '当前保险',
  'page.cerp.insurance.form.label.month_insurance_fee': '每月保费',
  'page.cerp.insurance.form.label.policy_no': '保险单号',
  'page.cerp.insurance.form.placeholder.adviser__full_name': '请选择隶属顾问',
  'page.cerp.insurance.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.insurance.form.placeholder.effective_date_time': '请填写起保日期时间',
  'page.cerp.insurance.form.placeholder.expiry_date_time': '请填写停保日期时间',
  'page.cerp.insurance.form.placeholder.insurance_company_id': '请选择保险公司',
  'page.cerp.insurance.form.placeholder.insurance_fee': '请填写保费',
  'page.cerp.insurance.form.placeholder.is_current': '请填写当前保险',
  'page.cerp.insurance.form.placeholder.month_insurance_fee': '请填写每月保费',
  'page.cerp.insurance.form.placeholder.policy_no': '请填写保险单号',
  'page.cerp.social_insurance.form.label.join_date': '入职日期', // '入职日期',
  'page.cerp.social_insurance.form.label.start_date': '起保月份', // '起保月份',
  'page.cerp.social_insurance.form.label.city': '缴纳城市', // '缴纳城市',
  'page.cerp.social_insurance.form.label.household_type': '户籍类型', // '户籍类型',
  'page.cerp.social_insurance.form.label.payment_item_ids': '缴纳项目', // '缴纳项目',
  'page.cerp.social_insurance.form.placeholder.join_date': '请填写入职日期', // '请填写入职日期',
  'page.cerp.social_insurance.form.placeholder.start_date': '请填写起保月份', // '请填写起保月份',
  'page.cerp.social_insurance.form.placeholder.city': '请填写缴纳城市', // '请填写缴纳城市',
  'page.cerp.social_insurance.form.placeholder.household_type': '请选择户籍类型', // '请选择户籍类型',
  'page.cerp.social_insurance.form.placeholder.payment_item_ids': '请选择缴纳项目', // '请选择缴纳项目',
  'page.cerp.contract.colums.column.title.accommodation_rate': '住宿费率',
  'page.cerp.contract.colums.column.title.action': '操作',
  'page.cerp.contract.colums.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.contract.colums.column.title.adviser__employee_number': '工号',
  'page.cerp.contract.colums.column.title.appendix_json_list': '附件',
  'page.cerp.contract.colums.column.title.insurance__effective_date_time': '保险起保日期', // '保险起保日期',
  'page.cerp.contract.colums.column.title.insurance__expiry_date_time': '保险停保日期', // '保险停保日期',
  'page.cerp.contract.colums.column.title.bank_charge': '银行转账费',
  'page.cerp.contract.colums.column.title.basic_rate': '基本单位薪资',
  'page.cerp.contract.colums.column.title.cc_email__name': 'CC邮箱',
  'page.cerp.contract.colums.column.title.cc_management_fee': 'cc管理费',
  'page.cerp.contract.colums.column.title.cc_company__name': 'CC公司',
  'page.cerp.contract.colums.column.title.cc_management_fee_rate': 'cc管理费率（%）',
  'page.cerp.contract.colums.column.title.create_time': '创建时间',
  'page.cerp.contract.colums.column.title.creater': '创建人',
  'page.cerp.contract.colums.column.title.currency__currency': '薪资币种',
  'page.cerp.contract.colums.column.title.pay_currency__currency': '付款币种',
  'page.cerp.contract.colums.column.title.email_record__log': '邮件发送日志',
  'page.cerp.contract.colums.column.title.email_record__status': '邮件发送状态',
  'page.cerp.contract.colums.column.title.email_record__create_time': '邮件发送时间',
  'page.cerp.contract.colums.column.title.end_client__name': '终端客户',
  'page.cerp.contract.colums.column.title.end_date': '合同结束日期',
  'page.cerp.contract.colums.column.title.id': 'ID',
  'page.cerp.contract.colums.column.title.index': '序号',
  'page.cerp.contract.colums.column.title.qiyuesuo_status': '合同状态',
  'page.cerp.contract.colums.column.title.insurance_type__name': '保险类别',
  'page.cerp.contract.colums.column.title.invoice_email_template__name': '请款单邮箱模板',
  'page.cerp.contract.colums.column.title.is_active': '是否可用',
  'page.cerp.contract.colums.column.title.is_bank_charge_payment': '银行转账费支付方式',
  'page.cerp.contract.colums.column.title.is_current': '是否当前合同',
  'page.cerp.contract.colums.column.title.insurance_payment_flag': '保险费支付方式',
  'page.cerp.contract.colums.column.title.local_deduct_fee': '本地扣减费',
  'page.cerp.contract.colums.column.title.local_salary': '本地发薪数值',
  'page.cerp.contract.colums.column.title.local_management_fee': '本地管理费',
  'page.cerp.contract.colums.column.title.local_management_fee_fixed': '本地发薪工资',
  'page.cerp.contract.colums.column.title.local_pay_currency__currency': '本地发薪币种',
  'page.cerp.contract.colums.column.title.local_management_fee_rate': '本地管理费率（%）',
  'page.cerp.contract.colums.column.title.management_fee_formula__name': '管理费计算方式',
  'page.cerp.contract.colums.column.title.management_fee_payment_flag': '管理费支付方式',
  'page.cerp.contract.colums.column.title.overtime_rate': '加班费率',
  'page.cerp.contract.colums.column.title.is_three_upload': '三方合同上传',
  'page.cerp.contract.colums.column.title.is_double_upload': '双方合同上传',
  'page.cerp.contract.colums.column.title.payslip_email_template__name': '工资单邮箱模板',
  'page.cerp.contract.colums.column.title.wechat_appendix_json_list': '小程序附件',
  'page.cerp.contract.colums.column.title.qiyuesuo_contract_id': '契约锁合同ID',
  'page.cerp.contract.colums.column.title.rate_type': '薪资种类',
  'page.cerp.contract.colums.column.title.recuitment_company__company_name': '顾问公司',
  'page.cerp.contract.colums.column.title.recuitment_company__vat_no': 'VATNo',
  'page.cerp.contract.colums.column.title.remark': '备注',
  'page.cerp.contract.colums.column.title.start_date': '合同起始日期',
  'page.cerp.contract.colums.column.title.termination_date': '终止日期',
  'page.cerp.contract.colums.column.title.update_time': '更新时间',
  'page.cerp.contract.colums.column.title.work_location__name': '工作地点',
  'page.Induction.personal_information': '基本信息',
  'page.Induction.Contract_signing': '合同签署',
  'page.Induction.bank_account': '银行账户',
  'page.Induction.Insurance_beneficiary': '保险受益人',
  'page.Induction.Important_tips': '重要提示',
  'page.Induction.Complete_the_onboarding_process': '完成入职流程',
  'page.Induction.preservation': '保存',
  'page.Induction.New_bank_account': '添加银行账户',
  'page.Induction.Modify_bank_account': '修改银行账户',
  'page.Induction.Required_reading_tips': '本人已充分知悉并同意由成诚公司收集员工的银行账户信息，并且员工可以自助修改。数据仅用于发放工资时使用。',
  'page.Induction.Click_to_view': '点击查看用户协议及隐私声明',
  'page.Induction.Re_sign': '重新签字',
  'page.Induction.sign': '签字',
  'page.Induction.confirm': '确认',
  'page.Induction.New_insurance_beneficiary': '添加保险受益人',
  'page.Induction.Modify_insurance_beneficiary': '修改保险受益人',
  'page.Induction.No_contract_signed': '当前无待签署合同',
  'page.Induction.form.label.full_name': '姓名',
  'page.Induction.form.label.other_name': '中文姓名',
  'page.Induction.form.label.nationality': '国籍',
  'page.Induction.form.label.mobile': '手机',
  'page.Induction.form.label.person_type_id': '个人类别',
  'page.Induction.form.label.wechat': '微信账号',
  'page.Induction.form.label.qq': 'QQ账号',
  'page.Induction.form.label.personal_email': '个人邮箱',
  'page.Induction.form.label.work_address_id': '工作地址',
  'page.Induction.form.label.title': '称谓',
  'page.Induction.form.label.birth_date': '出生日期',
  'page.Induction.form.label.phone': '电话',
  'page.Induction.form.label.status': '个人状态',
  'page.Induction.form.label.pay_currency_id': '付款币种',
  'page.Induction.form.label.whats_app': 'WhatsApp账号',
  'page.Induction.form.label.skypelid': 'Skype ID',
  'page.Induction.form.label.work_email': '工作邮箱',
  'page.Induction.form.label.home_address_id': '家庭地址',
  'page.Induction.form.title.New_work': '新建工作地址',
  'page.Induction.form.title.New_home': '新建家庭地址',
  'page.Induction.form.title.Created_successfully': '创建成功',
  'page.Induction.form.placeholder.full_name': '请填写姓名',
  'page.Induction.form.placeholder.other_name': '请填写中文姓名',
  'page.Induction.form.placeholder.nationality': '请选择国籍',
  'page.Induction.form.placeholder.mobile': '请填写手机号（格式请以00开头，如0086138xxxxxxxx）',
  'page.Induction.form.placeholder.person_type_id': '请选择个人类别',
  'page.Induction.form.placeholder.wechat': '请填写微信账号',
  'page.Induction.form.placeholder.qq': '请填写QQ账号',
  'page.Induction.form.placeholder.personal_email': '请填写个人邮箱',
  'page.Induction.form.placeholder.work_address_id': '请选择工作地址',
  'page.Induction.form.placeholder.title': '请选择称谓',
  'page.Induction.form.placeholder.birth_date': '请选择出生日期',
  'page.Induction.form.placeholder.phone': '请填写电话',
  'page.Induction.form.placeholder.status': '请选择个人状态',
  'page.Induction.form.placeholder.pay_currency_id': '请选择付款币种',
  'page.Induction.form.placeholder.whats_app': '请填写WhatsApp账号',
  'page.Induction.form.placeholder.skypelid': '请填写Skype ID',
  'page.Induction.form.placeholder.work_email': '请填写工作邮箱',
  'page.Induction.form.placeholder.home_address_id': '请选择家庭地址',
  'page.Induction.form.label.work_address_id.country_id': '国家',
  'page.Induction.form.label.work_address_id.state': '州/省',
  'page.Induction.form.label.work_address_id.city': '城市',
  'page.Induction.form.label.work_address_id.street': '街道',
  'page.Induction.form.label.work_address_id.postcode': '邮编',
  'page.Induction.form.placeholder.work_address_id.country_id': '请选择国家',
  'page.Induction.form.placeholder.work_address_id.state': '请填写州/省',
  'page.Induction.form.placeholder.work_address_id.city': '请填写城市',
  'page.Induction.form.placeholder.work_address_id.street': '请填写街道',
  'page.Induction.form.placeholder.work_address_id.postcode': '请填写邮编',
  'page.Induction.options.status_1': '-无-',
  'page.Induction.options.status_2': '工作中',
  'page.Induction.options.status_3': '结束',
  'page.Induction.options.status_4': '申请签证中',
  'page.Induction.options.status_5': '尚未开始',
  'page.Induction.options.status_6': '潜在雇员',
  'page.Induction.options.status_7': '离职',
  'page.Induction.options.status_8': '待定',
  'page.Induction.form_yin_hang_ZH.label.bankmanage_id': '选择银行',
  'page.Induction.form_yin_hang_ZH.label.bank_name': '银行名称',
  'page.Induction.form_yin_hang_ZH.label.english_name': '银行英文名称',
  'page.Induction.form_yin_hang_ZH.label.account_holder': '账户持有人',
  'page.Induction.form_yin_hang_ZH.label.account_number': '账号',
  'page.Induction.form_yin_hang_ZH.label.note': '备注',
  'page.Induction.form_yin_hang_ZH.label.bank_address': '开户行地址',
  'page.Induction.form_yin_hang_ZH.label.bic_or_swift_code': 'BIC/SWIFT CODE',
  'page.Induction.form_yin_hang_ZH.label.iban': 'IBAN',
  'page.Induction.form_yin_hang_ZH.label.cnaps': 'CNAPS',
  'page.Induction.form_yin_hang_ZH.label.is_current': '是否当前账号',
  'page.Induction.form_yin_hang_ZH.label.holder_address': '开户时向银行提供的住址',
  'page.Induction.form_yin_hang_ZH.label.sort_code_or_branch_code': 'SORT/BRANCH CODE',
  'page.Induction.form_yin_hang_ZH.placeholder.bankmanage_id': '请选择银行名称',
  'page.Induction.form_yin_hang_ZH.placeholder.bank_name': '请输入银行名称',
  'page.Induction.form_yin_hang_ZH.placeholder.english_name': '请选择银行英文名称',
  'page.Induction.form_yin_hang_ZH.placeholder.account_holder': '请填写账户持有人',
  'page.Induction.form_yin_hang_ZH.placeholder.account_number': '请填写账号',
  'page.Induction.form_yin_hang_ZH.placeholder.note': '请填写备注',
  'page.Induction.form_yin_hang_ZH.placeholder.bank_address': '请填写开户行地址',
  'page.Induction.form_yin_hang_ZH.placeholder.bic_or_swift_code': '请填写BIC/SWIFT CODE',
  'page.Induction.form_yin_hang_ZH.placeholder.iban': '请填写IBAN',
  'page.Induction.form_yin_hang_ZH.placeholder.sort_code_or_branch_code': '请填写SORT/BRANCH CODE',
  'page.Induction.form_yin_hang_ZH.placeholder.cnaps': '请填写CNAPS',
  'page.Induction.form_yin_hang_ZH.placeholder.holder_address': '请填写开户时向银行提供的住址',
  'page.Induction.form_yin_hang_ZH.rules.bank_name': '请填输入银行名称',
  'page.Induction.form_yin_hang_ZH.rules.account_holder': '请填输入账户持有人',
  'page.Induction.form_yin_hang_ZH.rules.account_number': '请填输入账号',
  'page.Induction.form_yin_hang_ZH.rules.bank_address': '请填输入开户行地址',
  'page.Induction.form_yin_hang_ZH.rules.is_current': '请填输入是否当前账号',
  'page.Induction.options_yin_hang_ZH.is_current.true': '是',
  'page.Induction.options_yin_hang_ZH.is_current.false': '否',


  'page.Induction.bank.message': '若无【银行账户】可直接点击【确认】',
  'page.Induction.bank.beneficiary': '若无【保险受益人】可直接点击【确认】',
  'page.Induction.rules.beneficiary.required': '请填输入姓名',
  'page.Induction.rules.beneficiary.gender': '请填输入性别',
  'page.Induction.rules.beneficiary.date_of_birth': '请填输入出生日期',
  'page.Induction.rules.beneficiary.id_number': '请填输入证件号',
  'page.Induction.options_Insurance_beneficiary.gender0': '男',
  'page.Induction.options_Insurance_beneficiary.gender1': '女',
  'page.Induction.options_Insurance_beneficiary.relation0': '本人',
  'page.Induction.options_Insurance_beneficiary.relation1': '配偶',
  'page.Induction.options_Insurance_beneficiary.relation2': '子女',
  'page.Induction.options_Insurance_beneficiary.relation3': '父母',
  'page.Induction.options_Insurance_beneficiary.relation4': '其他',
  'page.Induction.bankaccount': '银行账户',
  'page.Induction.beneficiary': '保险受益人',
  'page.Induction.message.adviser': '修改成功',
  'page.Induction.message.bank': '新建成功',
  'page.Induction.message.confirm': '确认成功',
  'page.Induction.message.reminder': '温馨提示',
  'page.Induction.adviser.description': '【个人信息】 未提交！',
  'page.Induction.contract.description': '【合同】签署 未提交！',
  'page.Induction.bankaccount.description': '【银行账户信息】 未提交！',
  'page.Induction.beneficiary.description': '【保险受益人信息】 未提交！',
  'page.Induction.tips.description': '【重要提示】 未提交！',
  'page.Induction.message.sign_contract': '顾问签署合同成功',
  'page.Induction.message.sign_contract_success': '入职流程完成',

  'page.Induction.information': '联系方式',
  'page.Induction.personal_address': '个人地址',
  'page.Induction.social_security': '社保',
  'page.Induction.resume': '个人简历',
  'page.Induction.form_can_kao_hao.label.name': '名称',
  'page.Induction.form_can_kao_hao.label.start_end_date': '开始-截止时间',
  'page.Induction.form_can_kao_hao.label.description': '编号',
  'page.Induction.form_can_kao_hao.label.reference_number_type_id':  '材料类别',
  'page.Induction.form_can_kao_hao.placeholder.name':  '请填写名称',
  'page.Induction.form_can_kao_hao.placeholder.start_end_date':  '请填写起保日期时间',
  'page.Induction.form_can_kao_hao.placeholder.description':  '请填写编号',
  'page.Induction.form_can_kao_hao.placeholder.reference_number_type_id':  '请选择材料类别',
  'page.Induction.form_can_kao_hao.rules.name':  '请填输入名称',
  'page.Induction.form_can_kao_hao.rules.relationship':  '请填输入关系',
  'page.Induction.form_can_kao_hao.rules.Telephone':  '请填写联系电话',
  'page.Induction.options_social_security.household_type0':  '本地城镇',
  'page.Induction.options_social_security.household_type1':  '本地农村',
  'page.Induction.options_social_security.household_type2':  '外地城镇',
  'page.Induction.options_social_security.household_type3':  '外地农村',
  'page.Induction.file':  '上传附件',
  'page.Induction.work_experience':  '工作经历',
  'page.Induction.Training_experience':  '培训经历',
  'page.Induction.UpdateAdviser.content':  '之前提交的记录还未审批，不可再修改',
  'page.Induction.UpdateAdviser.title':  '是否确认提交？',
  'page.Induction.UpdateAdviser.content1':  '提交审批后，等待审批处理了可继续修改',
  'page.Induction.UpdateBank.message':  '信息修改申请已提交，等待审核。',
  'page.Induction.openUrl.description':  '无对应附件',
  'page.Induction.delete_file.title':  '是否删除?',
  'page.Induction.AddEducations.title':  '添加教育背景',
  'page.cerp.AddEducations.form.label.start_end_date':  '起止时间',
  'page.cerp.AddEducations.form.label.school':  '学校',
  'page.cerp.AddEducations.form.label.major':  '专业',
  'page.cerp.AddEducations.form.label.education':  '学历',
  'page.cerp.AddEducations.form.placeholder.start_end_date':  '请填写起止时间',
  'page.cerp.AddEducations.form.placeholder.school':  '请填写学校',
  'page.cerp.AddEducations.form.placeholder.major':  '请填写专业',
  'page.cerp.AddEducations.form.placeholder.education':  '请填写学历',
  'page.cerp.AddEducations.form.placeholder.tip':  '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
  'page.cerp.AddExperiences.form.label.post': '职务',
  'page.cerp.AddExperiences.form.label.company': '公司',
  'page.cerp.AddExperiences.form.label.note': '工作描述',
  'page.cerp.AddExperiences.form.placeholder.start_end_date': '请选择时间',
  'page.cerp.AddExperiences.form.placeholder.company': '请填写公司',
  'page.cerp.AddExperiences.form.placeholder.post': '请填写职务',
  'page.cerp.AddExperiences.form.placeholder.note': '请填写工作描述',
  'page.cerp.AddExperiences.form.tip': '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
  'page.cerp.AddTraining.form.label.start_end_date': '时间',
  'page.cerp.AddTraining.form.label.book': '培训课程',
  'page.cerp.AddTraining.form.label.mechanism': '培训机构',
  'page.cerp.AddTraining.form.label.address': '培训地点',
  'page.cerp.AddTraining.form.label.note': '培训描述',
  'page.cerp.AddTraining.form.placeholder.start_end_date': '请选择时间',
  'page.cerp.AddTraining.form.placeholder.book': '请填写',
  'page.cerp.AddTraining.form.placeholder.mechanism': '请填写',
  'page.cerp.AddTraining.form.placeholder.address': '请填写（选填）',
  'page.cerp.AddTraining.form.placeholder.note': '请填写工作描述',
  'page.cerp.AddTraining.form.placeholder.tip': '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
  'page.information.see': '查看',
  'page.information.edit': '编辑',
  'page.information.Apply_for_modification': '申请修改',
  'page.information.New_personal_reference_number': '添加个人材料',
  'page.information.Modify_personal_reference_number': '修改个人材料',
  'page.information.New_emergency_contact': '添加紧急联系人',
  'page.information.Modify_emergency_contact': '修改紧急联系人',
  'page.information.New_insurance_beneficiary': '添加保险受益人',
  'page.information.Modify_insurance_beneficiary': '修改保险受益人',
  'page.information.Submit_payment_application': '提交代缴申请',
  'page.information.personal_information': '个人信息',
  'page.information.full_name': '姓名',
  'page.information.title': '称谓',
  'page.information.other_name': '中文姓名',
  'page.information.birth_date': '出生日期',
  'page.information.nationality__name': '国籍',
  'page.information.mobile': '手机',
  'page.information.phone': '电话',
  'page.information.wechat': '微信账号',
  'page.information.qq': 'QQ账号',
  'page.information.personal_email': '个人邮箱',
  'page.information.work_email': '工作邮箱',
  'page.information.whats_app': 'WhatsApp账号',
  'page.information.skypelid': 'Skype ID',
  'page.information.home_address__display_name': '家庭地址',
  'page.information.work_address__display_name': '工作地址',
  'page.information.educational_background': '教育背景',
  'page.information.add': '添加',
  'page.information.delete': '删除',
  'page.information.Missing_information': '缺少信息，请添加。',
  'page.information.educations.time': '时间',
  'page.information.educations.school': '学校',
  'page.information.educations.major': '专业',
  'page.information.educations.education': '学历',
  'page.information.educations.New_training': '新增培训',
  'page.information.educations.Attachment_upload': '附件上传',
  'page.cerp.AddData.title': '编辑额外信息',
  'page.cerp.AddData.form.label.datas': '额外信息',
  'page.cerp.AddData.form.label.tip': '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
  'page.cerp.AddData.form.placeholder.datas': '请填写额外信息',
  'page.cerp.AddSkill.title': '证书',
  'page.cerp.AddSkill.New_certificate': '新增证书',
  'page.cerp.AddSkill.form.label.datepicker': '获得时间',
  'page.cerp.AddSkill.form.label.certificate': '证书',
  'page.cerp.AddSkill.form.label.achievement': '成绩',
  'page.cerp.AddSkill.form.label.tip': '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
  'page.cerp.AddSkill.form.placeholder.datepicker': '请选择',
  'page.cerp.AddSkill.form.placeholder.certificate': '请填写证书',
  'page.cerp.AddSkill.form.placeholder.achievement': '请填写（选填）',
  'page.cerp.AddLanguage.title': '技能/语言',
  'page.cerp.AddLanguage.new_title': '新增技能/语言',
  'page.cerp.AddLanguage.form.label.language': '技能/语言',
  'page.cerp.AddLanguage.form.label.certificate': '掌握程度',
  'page.cerp.AddLanguage.form.placeholder.language': '请填写',
  'page.cerp.AddLanguage.form.placeholder.tip': '为了更新你的简历，请你点击“确定”保存并同意我们使用你的简历必要信息。如您不同意，请不要确定。',
  'page.cerp.AddLanguage.options.certificate1': '一般',
  'page.cerp.AddLanguage.options.certificate2': '良好',
  'page.cerp.AddLanguage.options.certificate3': '熟练',
  'page.cerp.AddLanguage.options.certificate4': '精通',
  'page.cerp.AddIntroduction.title': '编辑自我评价',
  'page.cerp.AddIntroduction.form.label.introduction': '自我评价',
  'page.cerp.AddIntroduction.form.placeholder.introduction': '请填写自我评价',


  'page.cerp.create_bank_account': '新增银行账户',
  'page.cerp.update': '修改',

  'page.cerp.payslip.colums.column.title.index': '序号',
  'page.cerp.payslip.colums.column.title.service_month': '请款月份',
  'page.cerp.payslip.colums.column.title.inovice_no': '请款单号',
  'page.cerp.payslip.colums.column.title.payslip_no': '工资单号',
  'page.cerp.payslip.colums.column.title.pay_time':'付款时间', //  '付款时间',
  'page.cerp.payslip.colums.column.title.pay_amount':'工资单金额', //  '工资单金额',
  'page.cerp.payslip.colums.column.title.status': '状态',
  'page.cerp.payslip.colums.column.title.pdf': 'PDF',
  'page.cerp.payslip.wages': '工资',
  'page.cerp.payslip.work_overtime': '加班',
  'page.cerp.payslip.Reimbursement': '报销',
  'page.pagemodel4.open': '展开',
  'page.pagemodel4.away': '收起',
  'page.pagemodel4.Basic_column': '基本列信息',
  'page.pagemodel4.Set_as_basic_configuration': '设为基础配置',
  'page.pagemodel4.Show_custom_columns': '展示自定义列',
  'page.pagemodel4.user': '用户',
  'page.pagemodel4.original': '原始',
  'page.pagemodel4.column': '【显示、隐藏】操作列',
  'page.pagemodel4.operation': '操作',
  'page.pagemodel4.Refresh': '刷新',
  'page.pagemodel4.density': '密度',
  'page.pagemodel4.default': '默认',
  'page.pagemodel4.middle': '中等',
  'page.pagemodel4.small': '紧凑',
  'page.pagemodel4.Column_display': '列展示',
  'page.pagemodel4.configure': '配置',
  'page.pagemodel4.Close_configuration': '关闭配置',
  'page.pagemodel4.display': '显示',
  'page.pagemodel4.hide': '隐藏',
  'page.pagemodel4.Column_settings': '列设置',
  'page.pagemodel4.Exit_full_screen': '退出全屏',
  'page.pagemodel4.full_screen': '全屏',
  'page.pagemodel4.total': '合计',
  'page.pagemodel4.Delete_this': '是否删除此项记录?',
  'page.pagemodel4.Deleted_successfully': '删除成功',
  'page.pagemodel4.recover_successful': '恢复成功',
  'page.pagemodel4.Created_successfully': '创建成功',
  'page.pagemodel4.update_successfully': '修改成功',
  'page.pagemodel4.Export_Excel': '导出Excel',
  'page.pagemodel4.Please_fill_in ': '请填写',
  'page.pagemodel4.Please_select': '请选择',
  'page.cerp.bankaccount.column.title.is_current.true': '是',
  'page.cerp.bankaccount.column.title.is_current.false': '否',
  'page.cerp.bankaccount.column.title.is_active.true': '可用',
  'page.cerp.bankaccount.column.title.is_active.false': '禁用',
  'page.cerp.bankaccount.column.title.is_active.all': '全部',
  'page.information.create_beneficiary': '新建保险受益人',
  'page.information.update_beneficiary': '修改',
  'page.information.update_nextofkin': '修改',
  'page.information.update_personal': '修改',
  'page.information.create_nextofkin': '新建紧急联系人',
  'page.information.create_personal': '新建个人参考号',
  'page.cerp.social_insurance.column.title.index': '序号',
  'page.cerp.social_insurance.column.title.adviser__full_name': '顾问',
  'page.cerp.social_insurance.column.title.adviser__employee_number': '工号',
  'page.cerp.social_insurance.column.title.join_date': '入职日期',
  'page.cerp.social_insurance.column.title.start_date': '起保月份',
  'page.cerp.social_insurance.column.title.city': '缴纳城市',
  'page.cerp.social_insurance.column.title.household_type': '户籍类型',
  'page.cerp.social_insurance.column.title.payment_items': '缴费项目',
  'page.cerp.social_insurance.column.title.create_time': '创建时间',
  'page.cerp.social_insurance.column.title.update_time': '更新时间',
  'page.Induction.options_social_security.all': '全部',

  'page.cerp.login.form.label.email': '邮箱地址',
  'page.cerp.login.form.placeholder.email': '请填写邮箱地址',
  'page.cerp.login.form.placeholder.Retrieve_password': '通过邮箱找回密码',
  'page.login.retrieve_password': '找回密码',
  'page.login.Account_password_login': '账号密码登录',
  'page.login.Wrong_account_or_password': '账户或密码错误',
  'page.login.Please_enter_your_account': '请输入账户',
  'page.login.Please_input_a_password': '请输入密码',
  'page.login.automatic_logon': '自动登录',
  'page.login.Forget_password': '忘记密码',
  'page.login.login': '登录',
  'page.login.Please_enter': '请输入帐户名或邮箱地址',
  'page.login.Modify_default_password': '修改默认密码',
  'page.login.enter': '请输入',
  'page.login.oldpassword': '旧密码',
  'page.login.newpassword': '新密码',
  'page.login.newpassword2': '确认密码',
  'page.login.Please_oldpassword': '请输入旧密码',
  'page.login.Please_newpassword': '请输入新密码',
  'page.login.reminder': '温馨提示',
  'page.login.pc': '当前不是PC设备，是否切换移动客户端',
  'page.login.The_two_passwords_are_inconsistent': '两次输入密码不一致',
  'page.login.Password_modified_successfully': '修改密码成功',
  'page.login.welcome': '欢迎',
  'page.login.welcome_back': '欢迎回来',
  'page.login.Send_mail': '发送邮件成功，请在10分钟内完成新密码设置。',
  'page.login.cancel': '取消',
  'page.login.determine': '确定',
  'page.retrieve_password.Set_new_password': '设置新密码',
  'page.retrieve_password.Update_password': '更新密码',
  'page.retrieve_password.Account_login': '账户登录',
  'page.retrieve_password.Password_updated_successfully': '密码更新成功',
  'page.upload_image_modal.OK_to_upload': '确定上传',
  'page.upload_image_modal.upload': '上传',
  'page.upload_image_modal.Created_successfully': '创建成功，可以【确认上传】',
  'page.upload_image_modal.Please_select': '请选择需要上传的附件',

  'page.Induction.img':  '图片发送',
  'page.Induction.text':  '文字',
  'page.Induction.Finger':  '手写',
  'page.Induction.jianli':  '导出简历',
  'page.Induction.Select_signing_method':  '选择签字方式',
  'page.Induction.Resume_not_created_yet':  '简历还未创建',
  'page.Induction.Signature_completed':  '签字完成',
  'page.Induction.Signing_contract_generation':  '签字合同生成中...',
  'page.Induction.Exit_failed':  '完成退出失败，请再次尝试或联系客服', // Exit failed. Please try again or contact customer service
  'page.information.Establish_integrity': '简历完整度',
  'page.cerp.AddExperiences.form.label.start_end_date': '时间',
  'page.kefu.name': '客服',
  'page.kefu.name1': '机器人',
  'page.kefu.close': '关闭',
  'page.kefu.chat': '点击左侧会话,开启聊天',
  'page.kefu.Send_pictures': '发送图片',
  'page.kefu.Send_link': '发送链接',
  'page.kefu.input': '请输入...',
  'page.kefu.send': '发送',
  'page.kefu.adviser': '顾问',
  'page.kefu.No_more_information_loaded': '无更多信息加载',
  'page.kefu.https': '请写http/https开头的链接',
  'page.kefu.Cannot_send_link': '当前不可发送链接',
  'page.kefu.Cannot_send_pictures': '当前不可发送图片',
  'page.kefu.link': '链接',
  'page.kefu.Link_content': '请填写链接内容',
  'page.kefu.Close_session': '确认关闭会话？',
  'page.kefu.Consulting': '咨询中',
  'page.kefu.Closed': '已关闭',
  'page.kefu.consult': '咨询',
  'page.kefu.Consultation_content': '咨询内容',
  'page.kefu.please_Consultation_content': '请填写咨询内容',
  'page.kefu.Session_created_successfully': '创建会话成功',
  'page.kefu.picture': '[图片]',
  'page.kefu.Read': '已读',
  'page.kefu.ask': '你是想问如下问题吗？',
  'page.kefu.no_ask': '未找到相关问题，请直接转人工！',
  'page.kefu.Unread': '未读',
  'page.kefu.Click_load_more': '点击加载更多',
  'page.kefu.labor': '转人工',
  'page.kefu.more': '更多问题',



  'page.kefu.Please_sign_in_the_blue_box': '请在蓝色方框内签字',
  'page.kefu.canvas': '亲，您的浏览器不支持canvas，换个浏览器试试吧！',
  'page.kefu.off_line': '离线',
  'page.kefu.on_line': '在线',
  'page.kefu.No_push': '无推送',
  'page.kefu.customer_service': '客服',
  'page.kefu.personal': '确认【个人信息】正确',
  'page.kefu.bank': '确认【银行账户】正确',
  'page.kefu.beneficiary': '本人确认无需保险，或按法定选择保险受益人。',
  'page.kefu.tips': '确认【重要提示】正确',
  'page.kefu.new_contract': '请签署新合同',
  'page.kefu.new_contract1': '确认已经完成[合同]内容的阅读。',
  'page.kefu.back': '上一步',
  'page.kefu.next': '下一步',
  'page.kefu.tips_read': '确认已经完成[重要提示]内容的阅读。',
  'page.kefu.gotoworkplace': '稍后完成',
  'page.kefu.gotoworkplace1': '确认稍后完成，返回欢迎页?',
  'page.Induction.Submit': '提交',
  'page.Induction.agree': '请勾选同意',
  'page.Personal.tip': '提示:当前您有修改申请尚未通过审批，请耐心等待.',
  'page.login.tip': '我已阅读并同意',
  'page.login.I_Know': '知道了',
  'page.login.tip1': '用户协议及隐私声明',// '《成诚顾问客户端》用户协议及隐私声明',
  'page.Induction.del': '有入职流程未完成，不可删除',// '有入职流程未完成，不可删除',
  'page.Induction.no': '无',// '无',
  'page.Induction.new_contract': '请签署新合同',// '无',
  'pages.workplace.diff_day': '您的当前合同已经过期，系统账号将在',
  'pages.workplace.diff_day1': '天后自动注销，如有计划续签合同，请尽快联系顾问公司。成诚公司期待与您的再次合作！',

  '票据合同签署': '票据合同签署',
  '票据合同': '票据合同',
  '合同起始日期': '合同起始日期',
  '合同结束日期': '合同结束日期',
  '操作': '操作',
  '合同签署详情': '合同签署详情',
  'CC公司': 'CC公司',
  '当前合同不支持客户签署': '当前合同不支持客户签署，请刷新列表后再尝试',
  '已完成签署': '已完成签署',
  '发票清单': '票据清单',
  '开票日期': '开票日期',
  '服务月份': '服务月份',
  '付款日期': '付款日期',
  '请选择开票日期': '请选择开票日期',
  '请选择服务月份': '请选择服务月份',
  '请选择付款日期': '请选择付款日期',
  '序号': '序号',
  'ID': 'ID',
  '票据种类': '票据种类',
  '全部': '全部',
  '总金额': '总金额',
  '币种': '币种',
  '付款状态': '付款状态',
  '未付款': '未付款',
  '已付款': '已付款',
  '附件': '附件',
  '提示': '提示',
  '无对应附件': '无对应附件',
  '登录过期': '登录过期',
  '请重新登录': '请重新登录',
};
